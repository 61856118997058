import classNames from "classnames";
import { FC, PropsWithChildren, useMemo } from "react";
import { BasicProps } from "./types/BasicProps";

type CheckboxProps = PropsWithChildren<{
  className?: string;
  onChange: (args: any) => void;
  checked: boolean;
  value: string;
  name: string;
  id: string;
  checkedBgColor?: "primary" | "pink" | "orange" | "green";
}>;

type Label = FC<BasicProps & { htmlFor: string }>;

type Checkbox = FC<CheckboxProps> & {
  Label: Label;
};

export const Checkbox: Checkbox = (props) => {
  const {
    className,
    children,
    onChange,
    checkedBgColor = "primary",
    ...restProps
  } = props;

  const _checkedBgColor = useMemo<string>(
    () =>
      checkedBgColor === "primary"
        ? "checked:bg-primary"
        : checkedBgColor === "pink"
        ? "checked:bg-[#B230E0]"
        : checkedBgColor === "orange"
        ? "checked:bg-[#FF9983]"
        : "checked:bg-[#83FFD2]",
    [checkedBgColor]
  );

  return (
    <div className={classNames("py-1 flex items-center gap-2", className)}>
      <input
        className={classNames(
          "w-3 h-3 border border-white rounded-sm",
          _checkedBgColor
        )}
        type="checkbox"
        onChange={() => onChange(restProps.value)}
        {...restProps}
      />
      {children}
    </div>
  );
};

const Label: Label = ({ children, className, ...restProps }) => {
  return (
    <label className={className} {...restProps}>
      {children}
    </label>
  );
};

Checkbox.Label = Label;
