"use client";
import { useEffect, useState, type FC } from "react";
import classNames from "classnames";
import { CtaSecondary } from "..";
import { CollectionItem } from "./CollectionItem";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { handleErrors } from "../../errors";
import { useErrorAlert } from "../../logic/hooks";
import dashboardModule, { CollectionData } from "../../logic/DashboardModule";
import { BasicProps } from "../types/BasicProps";
import { generalLiterals } from "../../literals";
import { app } from "app.config";

const LIMIT = 5;

const {
  seeMoreCtaLabel,
  title,
  totalNftsLockedLabel,
  tvlLabel,
  valuationRangeLabel,
} = generalLiterals.dashboard.collectionsPanel;

export const CollectionsPanel: FC<BasicProps> = (props) => {
  const { className } = props;
  const [error, setError] = useState<Error | null>(null);
  useErrorAlert(error);
  const [page, setPage] = useState<number>(0);
  const [collectionsData, setCollectionsData] = useState<CollectionData[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      try {
        const collectionsDataRetrieved =
          await dashboardModule.getCollectionsData();

        setCollectionsData(collectionsDataRetrieved);

        setLoading(false);
      } catch (err) {
        setError(handleErrors(err));
      }
    })();
  }, []);

  const handleSeeMore = () => {
    setPage(page + 1);
  };

  return (
    <section
      className={classNames("mx-auto p-0.5 border-unlockd rounded-xl", [
        className,
      ])}
    >
      <div className="pt-8 pr-[35px] pb-[38px] pl-[34px] bg-secondary rounded-xl">
        <h3 className="mb-6 font-bold text-2xl">{title}</h3>
        <table className="table-fixed w-full">
          <thead className="text-uyellow">
            <tr>
              <th className="w-[400px]"></th>
              <th className="font-normal text-[14px]">
                {totalNftsLockedLabel}
              </th>
              <th className="font-normal text-[14px]">{tvlLabel}</th>
              <th className="font-normal text-[14px]">{valuationRangeLabel}</th>
            </tr>
          </thead>
          <tbody>
            {collectionsData
              .slice(0, LIMIT * (page + 1))
              .map((collectionData, index) => (
                <CollectionItem
                  key={collectionData.address}
                  index={index + 1}
                  collectionData={collectionData}
                  isLastOne={index === collectionsData.length - 1}
                />
              ))}
          </tbody>
        </table>
        {!isLoading && app.COLLECTIONS.length > (page + 1) * LIMIT && (
          <div className="mt-10">
            <CtaSecondary className="mx-auto w-40" onClick={handleSeeMore}>
              {seeMoreCtaLabel}
            </CtaSecondary>
          </div>
        )}
        {isLoading && (
          <div className="w-full flex mx-auto mt-10 justify-center items-center">
            <AiOutlineLoading3Quarters className="animate-spin w-10 h-10" />
          </div>
        )}
      </div>
    </section>
  );
};
