import { Address } from 'viem';
import { uTokenFactoryContract } from '../contracts';
import TheGraph from '../thegraph';
import apyModule from './ApyModule';
import { getLoansOfAddress } from './helpers';
import UnlockdService from './UnlockdService';
import { externalWalletModule } from '../clients/verisModule';
import { equalIgnoreCase } from '@unlockdfinance/verislabs-web3/utils';
import { app } from 'app.config';
import { Erc20Currency } from './types/currency/Currency';
import currenciesModule from './CurrenciesModule';
import { calculateWeightedAverage } from 'utils';
import { ValueWithWeight } from 'utils/calculateWeightedAverage';

export type CollectionData = {
  address: string;
  totalNftsLocked: number;
  tvl: string;
  minimumVal: string;
  maximumVal: string;
  name: string;
  image: string;
  currency: Erc20Currency;
};

export type DashboardGeneralData = {
  totalValueLocked: bigint;
  utilizationRate: string;
  nftsDeposited: number;
  totalNftsValue: bigint;
};

export type DashboardUserData = {
  nftsDeposited: number;
  totalDebt: bigint;
  totalSupplied: bigint;
  interestEarned: bigint;
  claimedAuctions: number;
  onGoingAuctions: number;
  earnApy: number;
  borrowApy: number;
};

class DashboardModule {
  async getCollectionsData(): Promise<CollectionData[]> {
    const dataFromServer = await UnlockdService.getCollectionsData();

    const collectionsData: CollectionData[] = [];

    for (const [collectionAddress, collectionDataFromServer] of Object.entries(
      dataFromServer
    )) {
      const collection = app.COLLECTIONS.find((collection) =>
        equalIgnoreCase(collection.address, collectionAddress)
      );

      if (collection) {
        const currency = currenciesModule.getErc20CurrencyByAddress(
          collection.currenciesSupported[0].address
        )!;

        collectionsData.push({
          address: collection.address,
          image: collection.genericImage,
          maximumVal: collectionDataFromServer.max,
          minimumVal: collectionDataFromServer.min,
          name: collection.name,
          totalNftsLocked: collectionDataFromServer.nftCount,
          tvl: collectionDataFromServer.tvl,
          currency,
        });
      }
    }

    return collectionsData;
  }

  private async getTotalNftsDeposited() {
    const data = await TheGraph.client.getTotalCount({ id: '1' });

    if (!data.totalCount) {
      return 0;
    }

    return data.totalCount.totalCount;
  }

  async getDashboardGeneralData(): Promise<DashboardGeneralData> {
    const [nftsDeposited, collectionsData] = await Promise.all([
      this.getTotalNftsDeposited(),

      UnlockdService.getCollectionsData(),
    ]);

    // const nftsDeposited = await this.getTotalNftsDeposited();

    // const collectionsData = await UnlockdService.getCollectionsData();

    const currencies = currenciesModule.erc20Currencies;

    const { scaledTotalDebtMarket, totalValueLocked } = (
      await Promise.all(
        currencies.map(async (currency) => {
          const _totalSupplied = await uTokenFactoryContract.totalSupply(
            currency.address
          );
          const _scaledTotalDebtMarket =
            await uTokenFactoryContract.getScaledTotalDebtMarket(
              currency.address
            );

          const [totalSupplied, scaledTotalDebtMarket] = await Promise.all([
            currency.parseToDollar(_totalSupplied),
            currency.parseToDollar(_scaledTotalDebtMarket),
          ]);

          return {
            totalValueLocked: totalSupplied + scaledTotalDebtMarket,
            scaledTotalDebtMarket,
          };
        })
      )
    ).reduce(
      (acc, currentValue) => {
        acc.totalValueLocked += currentValue.totalValueLocked;
        acc.scaledTotalDebtMarket += currentValue.scaledTotalDebtMarket;

        return acc;
      },
      { totalValueLocked: BigInt(0), scaledTotalDebtMarket: BigInt(0) }
    );

    const utilizationRate =
      totalValueLocked !== BigInt(0) || scaledTotalDebtMarket !== BigInt(0)
        ? (
            (Number(scaledTotalDebtMarket) /
              (Number(totalValueLocked) + Number(scaledTotalDebtMarket))) *
            100
          ).toFixed(2)
        : '-.--';

    let totalNftsValuePromises: Promise<bigint>[] = [];

    for (const [collectionAddress, collectionData] of Object.entries(
      collectionsData
    )) {
      totalNftsValuePromises.push(
        new Promise(async (resolve, reject) => {
          try {
            const collection = app.COLLECTIONS.find((collection) =>
              equalIgnoreCase(collectionAddress, collection.address)
            );

            if (!collection) {
              reject(
                new Error(
                  `Collection with address ${collectionAddress} not found`
                )
              );
            } else {
              const currency = currenciesModule.getErc20CurrencyByAddress(
                collection.currenciesSupported[0].address
              )!;

              const _totalNftsValue = await currency.parseToDollar(
                BigInt(collectionData.tvl)
              );

              resolve(_totalNftsValue);
            }
          } catch (err) {
            throw new Error(
              `Error parsing total nfts value for collection ${collectionAddress}`
            );
          }
        })
      );
    }

    const totalNftsValue = (await Promise.all(totalNftsValuePromises)).reduce(
      (acc, currentValue) => (acc += currentValue),
      BigInt(0)
    );

    return {
      totalValueLocked,
      utilizationRate,
      nftsDeposited,
      totalNftsValue,
    };
  }

  private async getNftsDepositedByUser(address: Address) {
    const loans = await getLoansOfAddress(
      address,
      TheGraph.LoanStatus.BORROWED
    );

    return loans.reduce((acc, loan) => {
      return acc + loan.assets.length;
    }, 0);
  }

  private async getOnGoingAuctionsAmount() {
    const data = await TheGraph.client.getOnGoingAuctions({
      first: 1000,
      skip: 0,
      user: externalWalletModule.address!,
    });

    if (!data.orders) {
      throw new Error('Error fetching ongoing auctions from subgraph');
    }

    return data.orders.length;
  }

  private async getClaimedAuctionsAmount() {
    const { orders } = await TheGraph.client.getClaimedAuctions({
      first: 1000,
      skip: 0,
      user: externalWalletModule.address!,
    });

    if (!orders)
      throw new Error('Error fetching claimed auctions from subgraph');

    return orders.length;
  }

  async getDashboardUserData(): Promise<DashboardUserData> {
    const walletAddress = externalWalletModule.address;

    if (!walletAddress) {
      throw new Error('User not connected');
    }

    const [nftsDeposited, onGoingAuctions, claimedAuctions] = await Promise.all(
      [
        this.getNftsDepositedByUser(walletAddress),
        this.getOnGoingAuctionsAmount(),
        this.getClaimedAuctionsAmount(),
      ]
    );

    // const { nftsDeposited, totalDebt } =
    //   await this.getNftsDepositedAndTotalDebt();

    // const onGoingAuctions = await this.getOnGoingAuctionsAmount();

    // const claimedAuctions = await this.getClaimedAuctionsAmount();

    const currencies = currenciesModule.erc20Currencies;

    const {
      totalSupplied,
      scaledBalanceOf,
      borrowApyInputsToCalculate,
      earnApyInputsToCalculate,
      totalDebt,
    } = (
      await Promise.all(
        currencies.map(async (currency) => {
          const [
            _totalSupplied,
            _scaledBalanceOf,
            _totalDebt,
            borrowApy,
            earnApy,
          ] = await Promise.all([
            uTokenFactoryContract.balanceOf(currency.address, walletAddress),
            uTokenFactoryContract.scaledBalanceOf(
              currency.address,
              walletAddress
            ),
            uTokenFactoryContract.getScaledTotalDebtFromUser(
              currency.address,
              walletAddress
            ),
            apyModule.getBorrowApy(currency),
            apyModule.getDepositApy(currency),
          ]);

          // const _totalSupplied = await uTokenFactoryContract.balanceOf(
          //   currency.address,
          //   walletAddress
          // );

          // const _scaledBalanceOf = await uTokenFactoryContract.scaledBalanceOf(
          //   currency.address,
          //   walletAddress
          // );

          // const _totalDebt =
          //   await uTokenFactoryContract.getScaledTotalDebtFromUser(
          //     currency.address,
          //     walletAddress
          //   );

          // const borrowApy = await apyModule.getBorrowApy(currency);

          // const earnApy = await apyModule.getDepositApy(currency);

          const [totalSupplied, scaledBalanceOf, totalDebt] = await Promise.all(
            [
              currency.parseToDollar(_totalSupplied),
              currency.parseToDollar(_scaledBalanceOf),
              currency.parseToDollar(_totalDebt),
            ]
          );

          return {
            totalSupplied,
            scaledBalanceOf,
            borrowApy,
            earnApy,
            totalDebt,
          };
        })
      )
    ).reduce(
      (acc, currentValue) => {
        acc.totalSupplied += currentValue.totalSupplied;
        acc.scaledBalanceOf += currentValue.scaledBalanceOf;
        acc.totalDebt += currentValue.totalDebt;
        acc.borrowApyInputsToCalculate.push({
          value: currentValue.borrowApy,
          weight: Number(currentValue.totalDebt),
        });
        acc.earnApyInputsToCalculate.push({
          value: currentValue.earnApy,
          weight: Number(currentValue.scaledBalanceOf),
        });

        return acc;
      },
      {
        totalSupplied: BigInt(0),
        scaledBalanceOf: BigInt(0),
        totalDebt: BigInt(0),
        borrowApyInputsToCalculate: [],
        earnApyInputsToCalculate: [],
      } as {
        totalSupplied: bigint;
        scaledBalanceOf: bigint;
        totalDebt: bigint;
        borrowApyInputsToCalculate: ValueWithWeight[];
        earnApyInputsToCalculate: ValueWithWeight[];
      }
    );

    const borrowApy = Number(
      calculateWeightedAverage(borrowApyInputsToCalculate)
    );

    const earnApy = Number(calculateWeightedAverage(earnApyInputsToCalculate));

    return {
      nftsDeposited,
      totalDebt,
      totalSupplied,
      interestEarned: totalSupplied - scaledBalanceOf,
      claimedAuctions,
      onGoingAuctions,
      earnApy,
      borrowApy,
    };
  }
}

export default new DashboardModule();
