import classNames from "classnames";
import { useMemo, type FC } from "react";
import { Tag } from "..";
import { getAltForNft } from "../../logic/helpers";
import NftImage from "./NftImage";

type Props = {
  className?: string;
  nfts: { collection: string; tokenId: string; name: string; image: string }[];
  nftsAmount?: number;
  whiteSquaresDisabled?: boolean;
  rounded: "sm" | "md" | "lg";
};

const getGridStyles = (length: number): string =>
  length === 1 ? "grid-cols-1 grid-rows-1" : "grid-cols-2 grid-rows-2";

export const MulticollateralImage: FC<Props> = (props) => {
  const { className, nfts, whiteSquaresDisabled, nftsAmount, rounded } = props;

  const hasOneImage = useMemo(() => nfts.length === 1, [nfts]);
  const roundedStyles = useMemo<string>(
    () =>
      rounded === "md"
        ? "rounded-md"
        : rounded === "lg"
        ? "rounded-lg"
        : "rounded-sm",
    [rounded]
  );

  const gridStyles = useMemo(() => getGridStyles(nfts.length), [nfts]);

  const emptySpaces = useMemo(
    () => (hasOneImage || nfts.length > 3 ? 0 : 4 - nfts.length),
    [nfts]
  );

  const firstEmptySpaceStyles = useMemo<string>(
    () =>
      rounded === "md"
        ? "rounded-bl-md"
        : rounded === "lg"
        ? "rounded-bl-lg"
        : "rounded-bl-sm",
    [rounded]
  );

  const secondEmptySpaceStyles = useMemo<string>(
    () =>
      rounded === "md"
        ? "rounded-br-md"
        : rounded === "lg"
        ? "rounded-br-lg"
        : "rounded-br-sm",
    [rounded]
  );

  return (
    <div className="relative rounded-b">
      <div
        className={classNames(
          `overflow-hidden grid`,
          className,
          gridStyles,
          roundedStyles
        )}
      >
        {nftsAmount && (
          <Tag className="border-[#83FFD2] z-50 absolute top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2">{`Loan - ${nftsAmount} nfts`}</Tag>
        )}
        {nfts.map(({ collection, name, tokenId, image }) => (
          <NftImage
            className={classNames(
              "z-10 w-full h-full flex justify-center items-center"
            )}
            src={image}
            key={collection + tokenId}
            alt={getAltForNft(name, tokenId)}
          />
        ))}
        {Array.from({ length: emptySpaces }, (_, i) => (
          <div
            key={i}
            className={classNames(
              "border-dotted border border-white",
              i === 0 ? firstEmptySpaceStyles : secondEmptySpaceStyles
            )}
          ></div>
        ))}
        {!whiteSquaresDisabled && (
          <>
            <div
              className={classNames(
                "absolute -top-1 -right-1 w-full h-full border border-white",
                roundedStyles
              )}
            ></div>
            <div
              className={classNames(
                "absolute -top-2 -right-2 w-full h-full border border-white",
                roundedStyles
              )}
            ></div>
          </>
        )}
      </div>
    </div>
  );
};
